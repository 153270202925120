import React, { useState, useEffect, useRef  } from 'react';

import {PlayListDto} from './Dto/PlayListDto';
import {ImageToShowDto} from './Dto/ImageToShowDto';
import './App.css';
import { httpDirectResultWithBody} from './Service/httpCall';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import  config  from './config';
import moment from 'moment'
import SlidePage from './SlidePage';
import CommercialPage from './CommercialPage';

interface ISlidePageData
{
     imageUrl:string,
     cacheIdentifier:string,
     alt:string,
     title:string,
     lastModified:string,
     showDiagnosticInfo:boolean,
     maxDisplayWidthPercentage:number
}

interface IPlayerProps
{
     uniqueId:string
}

const Player = (theProps:IPlayerProps) => {
  
  const [isFetchingInitialPlayList, setisFetchingInitialPlayList] = useState<boolean>(true);
  const [showCommercialPage, setShowCommercialPage] = useState<boolean>(false);
  const handle = useFullScreenHandle();

  const timeoutForNextSlide = useRef<NodeJS.Timeout|null>(null);
  const currentPlayList = useRef<ImageToShowDto[]>([]);
  const newPlayList = useRef<ImageToShowDto[]>([]);
  const  currentPlayListIndex = useRef<number>(0);
  
   const countSinceLastCommercialPage = useRef<number>(0);
   const tokenForImage = useRef<string>("");

   const allowCommercialPage = useRef<boolean>(false);
   

   const [useSlidePage1, setUseSlidePage1] = useState<boolean>(false);
   const useSlidePage1Ref = useRef<boolean>(false); //this is a hack..someting with usestate being async or sth

   const thePropsForPage1 = useRef<ISlidePageData|null>(null);
   const thePropsForPage2 = useRef<ISlidePageData|null>(null);
   
     
  useEffect(() => {

    initialFetchDataAsync(); //just launch the request, do not use async within useeffect
  }, []);


   
  const tickForNextSlide= () =>{

    if (allowCommercialPage.current === true) {
      countSinceLastCommercialPage.current++;

      if (countSinceLastCommercialPage.current === 20) {
        setShowCommercialPage(true);
        countSinceLastCommercialPage.current = 0;
        return;
      }
      setShowCommercialPage(false);
    }
    
    if (currentPlayList.current.length === 0) {
      console.log("No items to show");
      return;
    }

    var currentlyUsingPage1 = useSlidePage1Ref.current;
    console.log('Current useslidepage1', currentlyUsingPage1)
    
    currentPlayListIndex.current++;
    
    if (currentPlayListIndex.current >= currentPlayList.current.length){
      currentPlayListIndex.current = 0;
      if (newPlayList.current.length !== 0) {
        currentPlayList.current = [...newPlayList.current];
        newPlayList.current.length = 0;
      }
    }

    let newData = getSlideData();
    if (currentlyUsingPage1) { // update slot1 (we'll switch to slot 2, which should be pre-loaded)
      thePropsForPage1.current = newData;
    } else {
      thePropsForPage2.current = newData;
    }

    // update hack
    currentlyUsingPage1 = !currentlyUsingPage1;
    console.log('Setting useslidepage1 to', currentlyUsingPage1)
    
    setUseSlidePage1(()=>currentlyUsingPage1); // trigger a rerender (but us the ref to store the last situation-> bad, I know)
    useSlidePage1Ref.current = currentlyUsingPage1;
  }

  const getSlideData = ():ISlidePageData|null => {
    console.log("Index", currentPlayListIndex.current);
    if (currentPlayList.current.length === 0) {
      console.log("No data in playlist");
      return null;
    }

    let playListItemId:number = currentPlayList.current[currentPlayListIndex.current].playListItemId;
    let slideId:number = currentPlayList.current[currentPlayListIndex.current].slideId;
    let sessionId:string = currentPlayList.current[currentPlayListIndex.current].sessionId;
    let data:ISlidePageData = {
      //imageUrl: `${config.backEndApi}/api/GetSlideImage?sessionId=${sessionId}&playListItemId=${playListItemId}&slideId=${slideId}&token=${tokenForImage.current}`,
      imageUrl: `${config.backEndApi}/api/GetSlideImage?sessionId=${sessionId}&playListItemId=${playListItemId}&slideId=${slideId}`,
      cacheIdentifier: `sessionId=${sessionId}&playListItemId=${playListItemId}&slideId=${slideId}`,
      title: currentPlayList.current[currentPlayListIndex.current].title,
      alt: 'Missing image',
      lastModified:moment.utc(currentPlayList.current[currentPlayListIndex.current].lastUpdateTimeStampUtc).local().format("LLL"),
      showDiagnosticInfo:true,
      maxDisplayWidthPercentage:currentPlayList.current[currentPlayListIndex.current].maxPercentageOfDisplayWidthToUse
    };
    return data;

  }

  const tickForUpdatePlayListAsync= async() =>{

    let playListDto = await httpDirectResultWithBody<PlayListDto>(`${config.backEndApi}/api/GetSlides`, theProps.uniqueId);
    newPlayList.current = filterByHour(playListDto.imagesToShow); //we need to have a copy
    tokenForImage.current = playListDto.registrationCode;
    //document.cookie = `ionscreentoken=${playListDto.registrationCode}`;
  }

  function filterByHour(images:ImageToShowDto[]):ImageToShowDto[] {
    const now = new Date();
    console.log(`${now.getHours()}:${now.getMinutes()}`);
      

    return images.filter( x => {
      return  (x.showFromHour < now.getHours()  || (x.showFromHour  == now.getHours() && x.showFromMinute  <= now.getMinutes() ) ) &&
              (x.showUntilHour > now.getHours() || (x.showUntilHour == now.getHours() && x.showUntilMinute >= now.getMinutes() ) )
    });
  }


  const initialFetchDataAsync = async () => {
    
    //https://spblog.net/post/2019/06/04/building-single-page-application-with-react-msal-js-and-pnpjs
    // https://docs.microsoft.com/en-us/samples/azure-samples/active-directory-b2c-javascript-msal-singlepageapp/single-page-application-built-on-msaljs-with-azure-ad-b2c/
    //add 
    let playListDto = await httpDirectResultWithBody<PlayListDto>(`${config.backEndApi}/api/GetSlides`, theProps.uniqueId);
    setisFetchingInitialPlayList(false);

    currentPlayList.current = filterByHour(playListDto.imagesToShow);
    tokenForImage.current = playListDto.registrationCode;
    allowCommercialPage.current = playListDto.allowCommercialPage;
   
    TriggerSlideShow();

    // interval for updating the complete playList
    setInterval(
      async () => tickForUpdatePlayListAsync(),
      300000
    );

  }

  const TriggerSlideShow = () => {
    //load first, we're initialized as if we're usig page 2 currently, so prep page1
    var slideData = getSlideData();
    thePropsForPage1.current = slideData;

    tickForNextSlide();

    console.log('setting timeOutClass');
    timeoutForNextSlide.current = setInterval(
      () => tickForNextSlide(),
      8000
    );
  }

  

  const handleClick = (e:any) => {
    if (handle.active)
    {
      handle.exit();
    }
    else{
      handle.enter();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    //console.log(e.key);
    if (e.key === "ArrowRight") {
      if (timeoutForNextSlide.current !== null) {
        console.log('Moving to next slide');
        clearTimeout(timeoutForNextSlide.current as NodeJS.Timeout); //cancel current timer
        TriggerSlideShow();// show next and set the timer
      }
    }
    else if (e.key === "ArrowLeft") {
      if (timeoutForNextSlide.current !== null) {
        console.log('Moving to previous slide');
        clearTimeout(timeoutForNextSlide.current as NodeJS.Timeout); 

        //make sure to move one to the left
        currentPlayListIndex.current-=2;
        if (currentPlayListIndex.current < 0) {
          currentPlayListIndex.current = currentPlayList.current.length -2; // bc when showing the last slide, the counter will be set back to 0, so we can never go down from the last slide (problems for later)
        }

        TriggerSlideShow();// show at current index and set the timer
      }
    }
  };

  const getSlidePages = ():JSX.Element => {

    var secondPageClass = 'absolute z-10 top-0 left-0 right-0 bottom-0';
    if (useSlidePage1){
      secondPageClass += ' invisible'
      console.log('using page1')
    } else {
      console.log('using page2')
    }

    if (useSlidePage1 && (thePropsForPage1.current === null ||thePropsForPage1.current === undefined)){
      return <></>;
    }else if (!useSlidePage1 && (thePropsForPage2.current === null ||thePropsForPage2.current === undefined)){
      return <></>;
    }

    return (
      <>
        <div className='relative'>
          <div className={useSlidePage1?'visible':'invisible'}>
            {thePropsForPage1.current!== null ? 
              <SlidePage 
                title={thePropsForPage1.current.title} 
                lastModified={thePropsForPage1.current.lastModified} 
                imageUrl={thePropsForPage1.current.imageUrl} 
                cacheIdentifier={thePropsForPage1.current.cacheIdentifier}
                alt={thePropsForPage1.current.alt}
                showDiagnosticInfo={thePropsForPage1.current.showDiagnosticInfo}
                maxDisplayWidthPercentage={thePropsForPage1.current.maxDisplayWidthPercentage} />
              :<></>}
          </div>
          <div className={secondPageClass} >
            {thePropsForPage2.current!== null ? 
              <SlidePage 
                title={thePropsForPage2.current.title} 
                lastModified={thePropsForPage2.current.lastModified} 
                imageUrl={thePropsForPage2.current.imageUrl}
                cacheIdentifier={thePropsForPage2.current.cacheIdentifier}
                alt={thePropsForPage2.current.alt}
                showDiagnosticInfo={thePropsForPage2.current.showDiagnosticInfo}
                maxDisplayWidthPercentage={thePropsForPage2.current.maxDisplayWidthPercentage} />
              :<></>}
          </div>
        </div>
      </>
    );
  }

    //the jsx part  

    if (isFetchingInitialPlayList) {
      return (
        <div >
          ...
        </div>
      );
    }

    return (
      <>
        <FullScreen handle={ handle}>
          <div  
            className=''
            onClick={handleClick}
            tabIndex={0} // required for onkeyDown to Work
            onKeyDown={handleKeyDown}
            >
              {!showCommercialPage?getSlidePages(): <CommercialPage/>}
          </div>

        </FullScreen>
      </>
    );
  
} 

export default Player;
